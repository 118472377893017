<template>
  <section class="section">
    <div class="section-header">
      <h1>{{ $route.name }}</h1>
    </div>
    <div class="row">
      <div class="col-md-12">
        <div class="card">
          <div class="card-header justify-content-between">
            <h4>All Incoming Calls</h4>
            <router-link
              to="/calls/incoming/create"
              class="btn btn-icon icon-left btn-primary"
              style="margin-left: 15px"
              ><i class="far fa-plus"></i> Add Incoming Call</router-link
            >
          </div>
          <div class="card-body">
            <div class="table-responsive">
              <table class="table table-striped">
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Caller Name</th>
                    <th>Caller Phone</th>
                    <th>ID #</th>
                    <th>Date</th>
                    <th>Status</th>
                    <th>[Action]</th>
                  </tr>
                </thead>
                <tbody v-if="calls.length > 0">
                  <tr v-for="(call, index) in calls" :key="index">
                    <td>{{ index + 1 }}</td>
                    <td>{{ call.caller_name }}</td>
                    <td>{{ call.caller_phone }}</td>
                    <td class="text-center">
                      <router-link :to="`/tasks/view/${call.task_id}`" v-if="call.task_id">View Task</router-link>
                      <router-link :to="`/tickets?ticketId=${call.ticket_id}`" v-else-if="call.ticket_id">View Ticket</router-link>
                      <router-link to="#" v-else>None</router-link>
                      <br>
                      <small v-if="call.action_status !== false">
                        <span class="reduceFont badge" :class="call.action_status == 'Completed' || call.action_status == 'Closed' ? 'badge-success' : 'badge-warning'">{{ call.action_status }}</span>
                      </small>
                    </td>
                    <td>{{ $globalFunc.getDate(call.created_at) }}</td>
                    <td>
                      <div class="badge badge-success" v-if="call.status == 'resolved'">
                        {{ $globalFunc.capitalizeFirstLetter(call.status) }}
                      </div>
                      <div class="badge badge-warning" v-else>
                        {{ $globalFunc.capitalizeFirstLetter(call.status) }}
                      </div>
                    </td>
                    <td class="d-flex justify-content-between align-items-center">
                      <a
                        class="btn btn-sm text-info"
                        data-toggle="tooltip"
                        title="View Call"
                        @click.prevent="viewCall(call.id)"
                        ><i class="fas fa-eye"></i
                      ></a>
                      <a
                        v-if="call.status !== 'resolved'"
                        class="btn btn-sm text-success"
                        data-toggle="tooltip"
                        title="Resolved"
                        @click.prevent="resolveCall(call.id)"
                        ><i class="fas fa-check"></i
                      ></a>
                      <!-- <a
                        class="btn btn-danger btn-action"
                        data-toggle="tooltip"
                        title="Delete"
                        @click.prevent="deleteCall(call.id)"
                        ><i class="fas fa-trash"></i
                      ></a> -->
                    </td>
                  </tr>
                </tbody>
                <tbody v-else>
                  <tr>
                    <td colspan="7">No record found</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="d-flex justify-content-between align-items-center">
              <h6>
                <strong>Total Calls:</strong> {{ calls.length }}
              </h6>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import CallHelpers from "@/functions/actions/CallRequest";
import { mapState } from "vuex";
export default {
  name: "IncomingCalls",
  computed: {
    ...mapState(["loading"]),
  },
  mounted() {
    this.retrieveCalls()
  },
  data() {
    return {
      calls: []
    };
  },
  methods: {
    viewCall(callId) {
      this.$router.push({
        name: "View Incoming Calls",
        params: {
          callId
        }
      });
    },
    async retrieveCalls() {
      const response = await CallHelpers.retrieveIncomingCalls();
      if (response.status) {
        this.calls = response.incomingcalls;
      } else {
        this.showAlert(
          "Error occured",
          `${response.message}. Kindly reload the page`,
          "error"
        );
      }
    },
    async deleteCall(callId) {
      this.$confirm("Are you sure?").then(async () => {
        const response = await CallHelpers.deleteCall(callId)
        if (response.status) {
          this.calls = this.calls.filter(call => call.id != callId)
          this.showAlert('Success', 'Call deleted successfully', 'success')
        } else {
          this.showAlert('Error occured', response.message, 'error')
        }
      });
    },
    async resolveCall(callId) {
      this.$confirm("This action cannot be undone. Are you sure?").then(async () => {
        const response = await CallHelpers.resolveCall(callId)
        if (response.status) {
          const singleCall = this.calls.find(call => call.id == callId)
          singleCall.status = 'resolved'
          this.showAlert('Success', 'Call status updated', 'success')
        } else {
          this.showAlert('Error occured', response.message, 'error')
        }
      });
    },
    showAlert(title, text, type) {
      this.$fire({
        title,
        text,
        type: type ? type : "warning",
      });
    },
  },
};
</script>
<style scoped>
span.reduceFont {
  font-size: 8px !important;
}
</style>